@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Dancing+Script:wght@400..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Great+Vibes&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css');

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@layer components{
    .MyCell {
        @apply before:me-5 max-sm:grid grid-flow-col grid-cols-2 before:justify-self-end border border-gray-500  text-center;
    }
    .CatsName{@apply before:content-['Cat\'s_Name:']; font-weight: bold;}
    .SexAndColor{@apply before:content-['Sex_/_Color:'];}
    .DateOfBirth{@apply before:content-['Date_of_Birth:'];}
    .KittenChampion{@apply before:content-['Kitten_Champion:'];}
    .JuniorChampion{@apply before:content-['Junior_Champion:'];}
    .Champion{@apply before:content-['Champion:'];}
    .InternationalChampion{@apply before:content-['International_Champion:'];}

    #main-menu-toggle:checked ~ .main-menu-ul {
        visibility: visible;
        opacity: 1;
    }

    #submenu-toggle:checked ~ .sub-menu-gallery {
        visibility: visible;
        opacity: 1;
    }
    #md-submenu-toggle:checked ~ .sub-menu-gallery {
        visibility: visible;
        opacity: 1;
    }
    .catLogo{
        @apply w-32 h-24;
    }
    
}



