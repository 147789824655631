@import "https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Dancing+Script:wght@400..700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Great+Vibes&display=swap";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.MyCell {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  text-align: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: column;
}

.MyCell:before {
  content: var(--tw-content);
  justify-self: end;
  margin-inline-end: 1.25rem;
}

@media not all and (width >= 640px) {
  .MyCell {
    display: grid;
  }
}

.Champion:before {
  --tw-content: "Champion:";
  content: var(--tw-content);
}

#main-menu-toggle:checked ~ .main-menu-ul, #submenu-toggle:checked ~ .sub-menu-gallery, #md-submenu-toggle:checked ~ .sub-menu-gallery {
  visibility: visible;
  opacity: 1;
}

.catLogo {
  width: 8rem;
  height: 6rem;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.order-3 {
  order: 3;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-24 {
  height: 6rem;
}

.min-h-96 {
  min-height: 24rem;
}

.w-10 {
  width: 2.5rem;
}

.w-24 {
  width: 6rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-svw {
  width: 100svw;
}

.max-w-28 {
  max-width: 7rem;
}

.max-w-52 {
  max-width: 13rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-full {
  max-width: 100%;
}

.grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[calc\(100\%-2em\)\] {
  --tw-translate-y: calc(100% - 2em);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.self-baseline {
  align-self: baseline;
}

.text-nowrap {
  text-wrap: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-950 {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.bg-AquaSeafoamGreen-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 143 45 / var(--tw-bg-opacity));
}

.bg-LightSeaGreen-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 213 153 / var(--tw-bg-opacity));
}

.bg-LighterGreen {
  --tw-bg-opacity: 1;
  background-color: rgb(216 228 191 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[url\(\'\/src\/styles\/assets\/images\/alina\.jpg\'\)\] {
  background-image: url("alina.c2103e0d.jpg");
}

.bg-\[url\(\'\/src\/styles\/assets\/images\/forest-path\.jpg\'\)\] {
  background-image: url("forest-path.c9730bf1.jpg");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.fill-white {
  fill: #fff;
}

.p-2 {
  padding: .5rem;
}

.p-20 {
  padding: 5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.align-middle {
  vertical-align: middle;
}

.font-dancing {
  font-family: Dancing Script, cursive;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-myBrown-900 {
  --tw-text-opacity: 1;
  color: rgb(67 48 43 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

@media not all and (width >= 640px) {
  .max-sm\:CatsName:before {
    --tw-content: "Cat's Name:";
    content: var(--tw-content);
  }

  .max-sm\:CatsName {
    font-weight: bold;
  }

  .max-sm\:SexAndColor:before {
    --tw-content: "Sex / Color:";
    content: var(--tw-content);
  }

  .max-sm\:DateOfBirth:before {
    --tw-content: "Date of Birth:";
    content: var(--tw-content);
  }

  .max-sm\:KittenChampion:before {
    --tw-content: "Kitten Champion:";
    content: var(--tw-content);
  }

  .max-sm\:JuniorChampion:before {
    --tw-content: "Junior Champion:";
    content: var(--tw-content);
  }

  .max-sm\:Champion:before {
    --tw-content: "Champion:";
    content: var(--tw-content);
  }

  .max-sm\:InternationalChampion:before {
    --tw-content: "International Champion:";
    content: var(--tw-content);
  }
}

.placeholder\:py-5::placeholder {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.hover\:bg-LightSeaGreen-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 213 153 / var(--tw-bg-opacity));
}

.hover\:bg-LighterGreen:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(216 228 191 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-slate-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.focus\:border-slate-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.active\:bg-LightSeaGreen-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(194 213 153 / var(--tw-bg-opacity));
}

@media not all and (width >= 640px) {
  .max-sm\:grid {
    display: grid;
  }

  .max-sm\:hidden {
    display: none;
  }
}

@media (width >= 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:shrink-0 {
    flex-shrink: 0;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:bg-\[url\(\'\/src\/styles\/assets\/images\/forest-path\.jpg\'\)\] {
    background-image: url("forest-path.c9730bf1.jpg");
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:text-left {
    text-align: left;
  }
}

@media (width >= 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md\:ml-20 {
    margin-left: 5rem;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:min-h-dvh {
    min-height: 100dvh;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:align-middle {
    vertical-align: middle;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 1024px) {
  .lg\:ml-52 {
    margin-left: 13rem;
  }

  .lg\:mt-44 {
    margin-top: 11rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

@media (width >= 1280px) {
  .xl\:ml-64 {
    margin-left: 16rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}
/*# sourceMappingURL=index.2d1082ce.css.map */
